import React from 'react';
import Layout from "../components/Layout";
import NavThree from "../components/NavThree";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import Schools from "../components/Schools";
import TokenOne from "../components/token"

const PricingPage = () => {
    return (
        <Layout pageTitle="Our Schools | LUTH">
            <NavThree />
            <PageHeader title="" />
            <Schools />
            <Footer />
        </Layout>
    );
};

export default PricingPage;
